import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Cards from "./components/Cards";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import ContactUs from "./components/ContactUs";
import About from "./components/About";

const App = () => {
  return (
    <HelmetProvider>
      <div className="font-sans">
        <Header />
        <Hero />
        <Cards />
        <Testimonials />
        <Services />
        <About />
        <ContactUs />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default App;
