import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import PIFAS from "../assets/pifas-ab.jpg"; // Ensure path accuracy
import { HiMenu, HiX } from "react-icons/hi"; // For mobile menu icons

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleSmoothScroll = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Pifas AB - Byggkonsulter och projektledning i Skåne</title>
        <meta
          name="description"
          content="Pifas AB, konsultförmedling inom byggbranschen i Skåne, kopplar byggföretag med erfarna byggkonsulter inom projektledning och byggledning."
        />
        <meta
          name="keywords"
          content="byggkonsult Skåne, byggkonsulter, byggprojektledning, byggledning Skåne, byggkonsultföretag, byggkonsulter i Skåne, arkitektur, rekrytering byggbranschen"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://pifasab.com/" />
        <meta property="og:title" content="Pifas AB - Byggkonsulter i Skåne" />
        <meta
          property="og:description"
          content="Pifas AB är specialiserad på byggkonsulter inom projektledning och byggledning för byggföretag i Skåne."
        />
        <meta property="og:image" content={PIFAS} />
        <meta property="og:url" content="https://pifasab.com/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <header className="bg-white border-b">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex-1 flex items-center gap-4">
              <a
                className="block text-teal-600"
                onClick={() => handleSmoothScroll("top")}
                style={{ cursor: "pointer" }}
              >
                <span className="sr-only">Pifas AB - Home</span>
                <img
                  src={PIFAS}
                  alt="Pifas AB Logo - Byggkonsulter i Skåne"
                  className="h-10 w-10 md:h-14 md:w-14"
                />
              </a>
            </div>

            {/* Desktop Navigation */}
            <nav aria-label="Global" className="hidden md:flex">
              <ul className="flex items-center gap-6 text-sm">
                <li>
                  <button
                    className="text-gray-500 transition hover:text-gray-700"
                    onClick={() => handleSmoothScroll("about-section")}
                  >
                    Om oss
                  </button>
                </li>
                <li>
                  <button
                    className="text-gray-500 transition hover:text-gray-700"
                    onClick={() => handleSmoothScroll("contact-section")}
                  >
                    Kontakta oss
                  </button>
                </li>
                <li>
                  <button
                    className="text-gray-500 transition hover:text-gray-700"
                    onClick={() => handleSmoothScroll("services")}
                  >
                    Tjänster
                  </button>
                </li>
              </ul>
            </nav>

            {/* Mobile Menu Button */}
            <div className="block md:hidden">
              <button
                className="rounded p-2 text-gray-600 transition hover:text-gray-700"
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                aria-label="Toggle mobile menu"
              >
                {isMobileMenuOpen ? (
                  <HiX className="h-6 w-6" />
                ) : (
                  <HiMenu className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile Navigation */}
          {isMobileMenuOpen && (
            <nav className="md:hidden" aria-label="Mobile navigation">
              <ul className="flex flex-col items-center gap-4 py-4 text-sm">
                <li>
                  <button
                    className="text-gray-600 transition hover:text-gray-800"
                    onClick={() => {
                      handleSmoothScroll("about-section");
                      setMobileMenuOpen(false);
                    }}
                  >
                    Om oss
                  </button>
                </li>
                <li>
                  <button
                    className="text-gray-600 transition hover:text-gray-800"
                    onClick={() => {
                      handleSmoothScroll("contact-section");
                      setMobileMenuOpen(false);
                    }}
                  >
                    Kontakta oss
                  </button>
                </li>
                <li>
                  <button
                    className="text-gray-600 transition hover:text-gray-800"
                    onClick={() => {
                      handleSmoothScroll("services");
                      setMobileMenuOpen(false);
                    }}
                  >
                    Tjänster
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
