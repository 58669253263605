import React from "react";
import ConstructionImage from "../assets/undraw_under_construction_46pa.png";

const About = () => {
  return (
    <div
      id="about-section"
      className="flex flex-col sm:flex-row items-center max-w-screen-xl mx-auto py-16 px-4 sm:px-8 lg:px-16"
    >
      <div className="sm:w-1/2 p-5 sm:p-10 flex justify-center">
        <img
          src={ConstructionImage}
          alt="Under Construction"
          className="rounded-md max-w-full h-auto"
        />
      </div>
      <div className="sm:w-1/2 p-5 sm:p-10 text-center sm:text-left">
        <span className="text-green-500 text-sm font-semibold uppercase tracking-wide">
          Om oss
        </span>
        <h2 className="mt-4 mb-6 text-3xl sm:text-4xl font-extrabold text-gray-900">
          Lär er mer <span className="text-green-500">om vår verksamhet.</span>
        </h2>
        <p className="text-gray-600 leading-relaxed text-base sm:text-lg">
          Pifas AB är specialiserat på konsult- och rekryteringstjänster inom
          bygg- och industribranschen. Med över 30 års erfarenhet fokuserar vi
          på att leverera expertkompetens inom arbetsmiljö, säkerhetsledning och
          projektledning. Vårt team består av erfarna konsulter som har arbetat
          med stora projekt, där säkerhet och hållbarhet är centrala krav. Vi
          kopplar samman företag med kvalificerade experter inom BAS-U och
          BAS-P, HSEQ-ledning, samt operativa roller för att säkerställa att
          projekt genomförs säkert, effektivt och enligt gällande regler och
          standarder. Genom att förstå våra kunders unika behov, erbjuder vi
          skräddarsydda lösningar som stärker både kvalitet och säkerhet i varje
          projekt. <span className="text-green-500">Kontakta oss idag!</span>
        </p>
      </div>
    </div>
  );
};

export default About;
